<template>
    <div class="rules-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="rules-list row g-2 mb-4">
                <div class="col-md-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search rules"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <div class="col-md-6 col-lg-auto">
                    <ui-select
                        id="roleFilter"
                        class="w-100"
                        name="role"
                        label="Role"
                        option-label="label"
                        option-value="value"
                        chose-option-text="All"
                        v-model:value="filters.role"
                        :options="roles"
                    />
                </div>
                <div class="col-auto ms-auto">
                <ui-button variant="primary" :to="`/profiles/register`" v-if="CAN(['write_profiles'])" :disabled="!CAN(['write_profiles'])"> 
                    {{ 'Create Profile' }}
                </ui-button>
            </div>
            </div>
            <ui-table class="mb-4" :items="profiles" :fields="fields" :busy="busy" :scopes="['read_profiles', 'write_profiles']">
                <template #cell(name)="data">
                    <ui-link :to="`/profiles/info?id=${data.item.id}`">
                        {{ data.item.name }}
                    </ui-link>
                </template>
                <template #cell(role)="data">
                    <strong>{{ data.item.role }}</strong>
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>   
                <template #cell(updated_at)="data">
                    {{ $dayjs(data.item.updated_at).format('LL') }} <br />
                    {{ $dayjs(data.item.updated_at).format('LTS') }}
                </template> 
                <template #cell(actions)="data">
                    <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                        <template #button-content>
                            <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item :link="`/profiles/info?id=${data.item.id}`" v-if="CAN(['read_profiles', 'write_profiles'])">
                            {{ 'See Details' }}
                        </b-dropdown-item>
                        <b-dropdown-item :link="`/profiles/edit?id=${data.item.id}`" v-if="CAN(['write_profiles'])">
                            Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item.id)" v-if="CAN(['write_profiles'])">
                            Delete
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
                <template #cellFull="data">
                    <tr v-if="data.item.error">
                        <td class="col-full" :colspan="fields.length">
                            <p class="text-center text-red p-3 mb-0">
                                <span class="fas fa-info-circle"></span>
                                {{ data.item.error }}
                            </p>
                        </td>
                    </tr>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
        <delete-profile-modal ref="removeModal" @removed="getProfile"/>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button';
import UiLink from '@/components/ui/Link';
import UiInput from '@/components/ui/Input';
import UiPagination from '@/components/ui/Pagination';
import UiTable from '@/components/ui/Table';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton';
import UiSelect from '@/components/ui/Select';
import UiStatus from '@/components/ui/BadgeStatus';
import DeleteProfileModal from '@/components/profiles/DeleteProfileModal';

import table_mixin from '@/mixins/tables.mixin';

export default {
    components: {
        UiInput,
        UiPagination,
        UiTable,
        UiTableSkeleton,
        UiSelect,
        UiStatus,
        UiLink,
        UiButton,
        DeleteProfileModal,
    },
    mixins: [table_mixin],
    data() {
        return {
            busy: false,
            profiles: null,
            creating: false,
            fields: [
                { key: 'name', label: 'Name' },
                { key: 'role', label: 'Role'},
                { key: 'created_at', label: 'Created at' },
                { key: 'updated_at', label: 'Updated at' },
                { key: 'actions', label: 'Actions' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                role: undefined,
            },
            inititialLoading: true,
            loading: false,
            total_pages: null,
            roles: [
                { label: 'Admin', value: 'admin' },
                { label:  'User', value: 'user' },
            ],
        };
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getProfile);
            },
        }
    },
    methods: {
        async getProfile() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    active: this.filters.active,
                    role: this.filters.role,
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/profiles/all', { params });

                this.total_pages = data.pages;
                this.profiles = data.docs;          
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
        async remove(id) {
            this.$refs.removeModal.openModal(id);
        }
    },
};
</script>