<template>
    <div>
        <b-modal title="Remove profile" ref="removeModal">
            <p> {{ 'Are you sure you want to remove this profile, once deleted it cannot be recovered.?' }} </p>
            <template #footer>
                <div class="text-end">
                    <ui-button variant="danger" @click="remove" :loading="loading">
                        Remove
                    </ui-button>
                </div>
                <ui-button variant="secondary" @click="closeModal"> Cancel </ui-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button';

export default {
    name: 'RemoveModal',
    components: { UiButton },
    data() {
        return {
            loading: false,
            profile_id: '',
        }
    },
    methods: {
        openModal(profile_id) {
            this.profile_id = profile_id;
            this.$refs.removeModal.show();
        },
        closeModal() {
            this.$refs.removeModal.hide();
        },
        async remove() {
            try {
                this.loading = true;
                const { data } = await this.$axios.delete(`/_/profiles/remove/${this.profile_id}`);
                this.$emit('removed');
                this.$toast.success('Removed profile successfully');
            } catch (err) {
                this.$toast.error(this.getErrorMessage(err));
            } finally {
                this.loading = false;
                this.closeModal();
            }
        }
    }
}
</script>